import { dotProGraphQuery } from './DotProGraphExternal';
import { Movie } from './GetFilmsAndShowtimes';

export type LocaleData = {
  title: string;
  synopsis: string;
  poster: string;
};
export type LocalizedMovie = {
  publicId: number;
  en_GB: LocaleData;
  fallback: LocaleData;
  mainRelease: {
    certificate: string;
  };
};

export const getLocalizedMovies = async (
  encryptedIds: string[]
): Promise<Map<string, LocalizedMovie>> => {
  const movieIds = encryptedIds.map((id) => atob(id).split(':')[1]);
  const query = `
    query {
      external {
        movies {
          localeMovies: getByIds(ids:[${movieIds.join(',')}]) {
            publicId
            en_GB: localeData(locale:"en_GB") {
              title
              synopsis
              poster
            }
            fallback: localeData(locale:"en_US") {
              title
              synopsis
              poster
            }
            mainRelease(country: "uk") {
                certificate
            }
          }
        }
      }
    }`;

  const result = await dotProGraphQuery(query, 'external');
  const localizedMovies: Map<string, LocalizedMovie> = new Map();
  result.data.external.movies.localeMovies.forEach(
    (element: LocalizedMovie) => {
      localizedMovies.set(btoa(`Movie:${element.publicId}`), element);
    }
  );
  return localizedMovies;
};

export const getBestTitle = (movie: Movie) => {
  return (
    movie.en_GB?.title || movie.fallback?.title || movie.originalTitle || ''
  );
};

export const getBestPoster = (movie: Movie) => {
  return (
    movie.en_GB?.poster || movie.fallback?.poster || movie.originalPoster || ''
  );
};

import * as React from 'react';

import './my-location.scss';

interface PropsTypes {
  children?: any;
  handlePosition: any;
}

export const MyLocationTerm = 'use my location';

export const MyLocation = (props: PropsTypes) => {
  if (
    typeof window === 'undefined' ||
    window === null ||
    typeof window.navigator === 'undefined' ||
    window.navigator === null ||
    typeof window.navigator.geolocation === 'undefined' ||
    window.navigator.geolocation === null
  ) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return (
    <div className="my-location">
      <div className="my-location__label">{props.children}</div>
      <button
        type="button"
        className="my-location__button"
        onClick={props.handlePosition}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className="my-location__button__icon"
          aria-hidden="true"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill="currentColor"
            d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
        <span className="my-location__button__text">Use my location</span>
      </button>
    </div>
  );
};

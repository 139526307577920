import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import * as React from 'react';
import {
  createContentGroup,
  createContentGroupItem,
} from './components/content-group/content-group';
import { CookieConsentUpdate } from './components/cookie-consent/cookie-consent';

const createIntroText = (node: any) => {
  if (
    typeof node.data.target.fields !== 'undefined' &&
    node.data.target.fields !== null &&
    typeof node.data.target.fields.content !== 'undefined' &&
    node.data.target.fields.content !== null
  ) {
    return (
      <div className="page-content__intro">
        {documentToReactComponents(node.data.target.fields.content['en-GB'])}
      </div>
    );
  }
  return null;
};

export const MarkupOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => {
      return <strong key={`${text}-key`}>{text}</strong>;
    },
    [MARKS.ITALIC]: (text: any) => {
      return (
        <span style={{ fontStyle: 'italic' }} key={`${text}-key`}>
          {text}
        </span>
      );
    },
  },
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node: any) => {
      if (
        typeof node !== 'undefined' &&
        node !== null &&
        typeof node.content !== 'undefined' &&
        node.content !== null &&
        node.content.length > 0 &&
        typeof node.data !== 'undefined' &&
        node.data !== null &&
        typeof node.data.target !== 'undefined' &&
        node.data.target !== null &&
        typeof node.data.target.fields !== 'undefined' &&
        node.data.target.fields !== null &&
        typeof node.data.target.fields.pageUrl !== 'undefined' &&
        node.data.target.fields.pageUrl !== null &&
        typeof node.data.target.fields.pageUrl['en-GB'] !== 'undefined' &&
        node.data.target.fields.pageUrl['en-GB'] !== null
      ) {
        for (const c of node.content) {
          if (c.nodeType === 'text') {
            return (
              <a href={`/${node.data.target.fields.pageUrl['en-GB']}/`}>
                {c.value}
              </a>
            );
          }
        }
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      if (
        typeof node !== 'undefined' &&
        node !== null &&
        typeof node.data !== 'undefined' &&
        node.data !== null &&
        typeof node.data.target !== 'undefined' &&
        node.data.target !== null &&
        typeof node.data.target.sys !== 'undefined' &&
        node.data.target.sys !== null &&
        typeof node.data.target.sys.contentType !== 'undefined' &&
        node.data.target.sys.contentType !== null
      ) {
        switch (node.data.target.sys.contentType.sys.id) {
          case 'cookieConsent':
            return <CookieConsentUpdate />;
          case 'contentGroup':
            return createContentGroup(node, MarkupOptions);
          case 'contentGroupItem':
            return createContentGroupItem([node.data.target], MarkupOptions);
          case 'introText':
            return createIntroText(node);
        }
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      if (
        typeof node !== 'undefined' &&
        node !== null &&
        typeof node.data !== 'undefined' &&
        node.data !== null &&
        typeof node.data.target !== 'undefined' &&
        node.data.target !== null &&
        typeof node.data.target.fields !== 'undefined' &&
        node.data.target.fields !== null &&
        typeof node.data.target.fields.description !== 'undefined' &&
        node.data.target.fields.description !== null
      ) {
        const { description, file } = node.data.target.fields;
        const mimeType = file['en-GB'].contentType;
        const mimeGroup = mimeType.split('/')[0];

        switch (mimeGroup) {
          case 'image':
            return (
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={file['en-GB'].url + '?w=736'}
                />
                <img
                  alt={description['en-GB']}
                  className="page-content__image"
                  loading="lazy"
                  src={file['en-GB'].url}
                  height={file['en-GB'].details.image.height}
                  width={file['en-GB'].details.image.width}
                />
              </picture>
            );
          case 'video':
            return (
              <figure className="page-content__figure">
                <video
                  autoPlay={false}
                  className="page-content__video"
                  controls={true}
                >
                  <source src={file['en-GB'].url} type={mimeType} />
                  <p>
                    <a href={file['en-GB'].url}>Click here to view the video</a>
                  </p>
                </video>
                <figcaption className="page-content__figure__caption">
                  {description['en-GB']}
                </figcaption>
              </figure>
            );
        }
      }
    },
  },
  renderText: (text: any) => {
    return text
      .split('\n')
      .reduce((children: any, textSegment: any, index: number) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
  },
};

export const Sanitise = (value: string) => {
  return value.replace(/\+/g, ' ').replace(/</g, '').replace(/>/g, '');
};

export const GetUrlParameter = (name: string) => {
  if (
    typeof window === 'undefined' ||
    window === null ||
    typeof window.location === 'undefined' ||
    window.location === null
  ) {
    return '';
  }

  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const res = regex.exec(window.location.search);
  return res === null ? '' : decodeURIComponent(Sanitise(res[1]));
};

export const GetFriendlyName = (name: string) => {
  return name
    .toLowerCase()
    .trim()
    .replace(/^\W+|\W+$/, '')
    .replace(/\&/g, 'and')
    .replace(/'"/g, '')
    .replace(/_/g, '-')
    .replace(/\W+/g, '-')
    .replace(/\s/g, '-');
};

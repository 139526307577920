export const FORM_FILTERS_TO_API_FILTERS = {
  SUBTITLED: 'SUBTITLED',
  AUDIO_DESCRIPTION: 'AUDIO_DESCRIBED',
  AUTISM_FRIENDLY: 'AUTISM_FRIENDLY',
  DEMENTIA_FRIENDLY: 'DEMENTIA_FRIENDLY',
  WHEELCHAIR_ACCESSIBLE: 'WHEELCHAIR_ACCESSIBLE',
};

export type FORM_FILTERS =
  | 'SUBTITLED'
  | 'AUDIO_DESCRIPTION'
  | 'AUTISM_FRIENDLY'
  | 'DEMENTIA_FRIENDLY'
  | 'WHEELCHAIR_ACCESSIBLE';

export type API_FILTERS =
  | 'SUBTITLED'
  | 'AUDIO_DESCRIBED'
  | 'AUTISM_FRIENDLY'
  | 'DEMENTIA_FRIENDLY'
  | 'WHEELCHAIR_ACCESSIBLE';

export type API_TAGS = 'Showtime_Accessibility_AudioDescription';

export const API_TAGS_TO_FORM_FILTERS = {
  Showtime_Accessibility_AudioDescription: 'AUDIO_DESCRIPTION',
  Showtime_Accessibility_AutismFriendly: 'AUTISM_FRIENDLY',
  Showtime_Accessibility_DementiaFriendly: 'DEMENTIA_FRIENDLY',
  Showtime_Accessibility_ClosedCaption: 'SUBTITLED',
  Showtime_Accessibility_Subtitled: 'SUBTITLED',
  Showtime_Accessibility_OpenCaption: 'SUBTITLED',
  Showtime_Accessibility_Accessible: 'WHEELCHAIR_ACCESSIBLE',
};

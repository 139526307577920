const API_ENDPOINT = 'https://api.webediamovies.pro/graphql';

const API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBOYW1lIjoidWtjYSIsImlhdCI6MTU5NzY3NDQ5NSwiZXhwIjoxNzcwNDc0NDk1fQ.x0uZkUpNfNHtOYRXe5_rUUJciK53veEQBOdo5WA6rsQ';

export function dotProGraphQuery(query: string, resultKey: string) {
  return fetch(API_ENDPOINT, {
    body: JSON.stringify({ query }),
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then((r) => r.json())
    .then((r) => {
      if (
        typeof r === 'undefined' ||
        r === null ||
        typeof r.data === 'undefined' ||
        r.data === null ||
        typeof r.data[resultKey] === 'undefined' ||
        r.data[resultKey] === null
      ) {
        throw Error(r);
      }
      return r;
    });
}

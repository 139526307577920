import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';
import { LoadGA } from './google-analytics';

import './cookie-consent-prompt.scss';

export const CookieConsentPrompt = () => {
  const data = useStaticQuery(graphql`
    query CookieConsentPromptQuery {
      contentfulCookieConsent {
        heading
        text {
          json
        }
        promptButtonText
        promptManageButtonText
        updatePageLink {
          pageUrl
        }
      }
      cinemaInfo: contentfulPage(pageType: { eq: "cinema information" }) {
        pageUrl
        seoTitle
      }
    }
  `);

  const content = data.contentfulCookieConsent;

  const allSettings =
    typeof document !== 'undefined' &&
    document.cookie.indexOf('CookieConsent=') !== -1
      ? JSON.parse(
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                '(?:(?:^|.*;)\\s*' +
                  'CookieConsent' +
                  '\\s*\\=\\s*([^;]*).*$)|^.*$'
              ),
              '$1'
            )
          )
        )
      : {
          analytics: false,
        };

  const [isSetup, changeIsSetup] = React.useState(false);
  const [hasCookie, updateHasCookie] = React.useState(true);

  const setup = () => {
    if (isSetup) {
      return;
    }

    changeIsSetup(true);

    if (document.cookie.indexOf('CookieConsent=') === -1) {
      updateHasCookie(false);
    }
  };

  const handleSave = () => {
    const tempDate = new Date();
    allSettings.analytics = true;
    tempDate.setDate(tempDate.getDate() + 30);
    document.cookie =
      'CookieConsent=' +
      JSON.stringify(allSettings) +
      '; path=/; secure=true; SameSite=Strict; expires=' +
      tempDate.toUTCString();

    processCookieSettings();

    const prompt = document.getElementById('cookieConsent');

    if (prompt !== null) {
      const jQuery = require('jquery');

      jQuery(prompt).slideUp(600);
    }

    const toggleAnalytics: any = document.getElementById(
      'cookieConsentAnalytics'
    );

    if (toggleAnalytics !== null) {
      toggleAnalytics.checked = true;
    }
  };

  const processCookieSettings = () => {
    if (allSettings.analytics) {
      const dynamicUrls = [`/${data.cinemaInfo.pageUrl}/`];

      const isDynamicUrl =
        dynamicUrls.filter((url: string) => {
          return (
            document.location.pathname.indexOf(url) === 0 &&
            document.location.pathname.length > url.length
          );
        }).length > 0;
      LoadGA(isDynamicUrl);
    }
  };

  React.useEffect(() => {
    if (document.cookie.indexOf('CookieConsent=') === -1) {
      setup();
    } else {
      processCookieSettings();
    }
  });

  return (
    <React.Fragment>
      {hasCookie === false && (
        <div
          aria-describedby="cookieConsentText"
          aria-labelledby="cookieConsentTitle"
          className="cookie-consent"
          id="cookieConsent"
          role="alertdialog"
        >
          <div className="contain">
            <div className="contain__column">
              <div className="cookie-consent__intro">
                <h2
                  className="cookie-consent__intro__heading"
                  id="cookieConsentTitle"
                >
                  {content && content.heading}
                </h2>
                <div
                  className="cookie-consent__intro__text"
                  id="cookieConsentText"
                >
                  {content &&
                    content.text &&
                    content.text.json &&
                    documentToReactComponents(content.text.json, MarkupOptions)}
                </div>
              </div>
              <div className="cookie-consent__ctas">
                <button
                  className="btn cookie-consent__button"
                  onClick={handleSave}
                  type="button"
                >
                  {content.promptButtonText}
                </button>
                <a
                  className="cookie-consent__manage"
                  href={`/${content.updatePageLink.pageUrl}/#cookiesettings`}
                >
                  {content.promptManageButtonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

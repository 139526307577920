import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { CinemaContext } from './cinema-context';
import { createContentGroupItem } from '../../components/content-group/content-group';
import {
  GetFriendlyName,
  GetUrlParameter,
  MarkupOptions,
  Sanitise,
} from '../../utilities';
import { MyLocation, MyLocationTerm } from '../my-location/my-location';
import { TrackGAPageView } from '../cookie-consent/google-analytics';

import './cinema-finder.scss';
import { graphQuery } from '../../utils/AcGraphApi';

interface PropsTypes {
  heading?: string;
  text?: any;
}

const apiLimit =
  typeof process.env.GATSBY_API_LIMIT !== 'undefined'
    ? process.env.GATSBY_API_LIMIT
    : '120';
const companyId =
  typeof process.env.GATSBY_API_ID !== 'undefined'
    ? process.env.GATSBY_API_ID
    : '';

export const CinemaFinder = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query CinemaFinderQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          alias
          link {
            pageUrl
          }
        }
      }
      cinemaFinder: contentfulCinemaFinder {
        accessibilityInformationHeading
        buttonText
        cinemaLabel
        cinemaLoadingText
        cinemaPlaceholder
        contactHeading
        locationLabel
        locationPlaceholder
        locationRadius
        noResultsMessage {
          json
        }
        resultsHeading
        resultsLoadingText
        screeningTypesHeading
        screensHeading
      }
      cinemaInfo: contentfulPage(pageType: { eq: "cinema information" }) {
        pageUrl
        seoTitle
      }
    }
  `);

  const attributes = data.attributes.nodes;
  const cmsContent = data.cinemaFinder;
  const cinemaInfo = data.cinemaInfo;

  const rawCinemas = React.useContext(CinemaContext);

  const [noResults, setNoResults] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [cinemas, setCinemas] = React.useState([]);
  const [calledGetSelectCinemas, setCalledGetSelectCinemas] = React.useState(
    false
  );
  const [searchValue, setSearchValue] = React.useState('');
  const [cinemaValue, setCinemaValue] = React.useState('');
  const [cinemaSelectValue, setCinemaSelectValue] = React.useState('');
  const [calledCheckPageUrl, setCalledCheckPageUrl] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cities, setCities] = React.useState([]);
  const [isPopStateSetup, setIsPopStateSetup] = React.useState(false);

  const resultContain = React.useRef(null);

  const checkPage = () => {
    if (calledCheckPageUrl || cinemas.length === 0) {
      return;
    }

    setCalledCheckPageUrl(true);

    if (cinemaValue !== '' || searchValue !== '') {
      searchCinemas();
    }
  };

  const scrollToResults = () => {
    if (
      typeof resultContain !== 'undefined' &&
      resultContain !== null &&
      typeof resultContain.current !== 'undefined' &&
      resultContain.current !== null
    ) {
      const jQuery = require('jquery');

      jQuery('html, body').animate(
        {
          scrollTop: jQuery(resultContain.current).offset().top - 50,
        },
        600
      );
    }
  };

  const getSelectCinemas = () => {
    if (calledGetSelectCinemas || rawCinemas.length === 0) {
      return;
    }

    setCalledGetSelectCinemas(true);

    let currentCinema = -1;

    const tempCitiesObj: any = {};
    const tempCities: any = [];

    rawCinemas.forEach((cinema: any, index: number) => {
      if (typeof cinema.node === 'undefined' || cinema.node === null) {
        return true;
      }

      if (
        typeof cinema.node.location !== 'undefined' &&
        cinema.node.location !== null &&
        typeof cinema.node.location.city !== 'undefined' &&
        cinema.node.location.city !== null &&
        typeof tempCitiesObj[cinema.node.location.city] === 'undefined'
      ) {
        tempCitiesObj[cinema.node.location.city] = true;
        tempCities.push(cinema.node.location.city);
      }

      if (
        typeof cinema.node.friendlyName !== 'undefined' &&
        cinema.node.friendlyName !== null
      ) {
        return true;
      }

      cinema.node.friendlyName = `/${cinemaInfo.pageUrl}/${GetFriendlyName(
        cinema.node.name
      )}`;

      if (
        window.location.pathname.toLowerCase() ===
        cinema.node.friendlyName.toLowerCase()
      ) {
        setCinemaValue(cinema.node.name);

        setCinemaSelectValue(cinema.node.friendlyName);

        currentCinema = index;

        if (
          typeof window.history.replaceState !== 'undefined' &&
          window.history.replaceState !== null
        ) {
          window.history.replaceState(
            {
              cinemaOption: cinema.node.friendlyName,
              cinemaValue: cinema.node.name,
            },
            ''
          );
        }
      }
    });

    if (
      currentCinema === -1 &&
      window.location.search.indexOf('search=') > -1
    ) {
      const searchParam = GetUrlParameter('search');

      setSearchValue(searchParam);

      if (
        typeof window.history.replaceState !== 'undefined' &&
        window.history.replaceState !== null
      ) {
        window.history.replaceState(
          {
            searchValue: searchParam,
          },
          ''
        );
      }
    }

    setCinemas(rawCinemas);

    tempCities.sort();

    setCities(tempCities);
  };

  const searchCinemas = (searchText?: string) => {
    if (typeof searchText === 'undefined' && cinemaValue !== '') {
      setSearchValue('');
      doSearch({
        limit: '1',
        search: cinemaValue,
      });
      return;
    }

    searchText = searchText || searchValue;

    if (searchText !== '') {
      setCinemaValue('');
      setCinemaSelectValue('');
      setSearchValue(searchText);

      if (GetUrlParameter('search') !== searchText) {
        const searchUrl = `/${cinemaInfo.pageUrl}/?search=${encodeURIComponent(
          searchText
        )}`;

        if (
          typeof window.history.pushState === 'undefined' ||
          window.history.pushState === null
        ) {
          window.location.href = searchUrl;
          return;
        }

        window.history.pushState(
          {
            searchValue: searchText,
          },
          '',
          searchUrl
        );
      }

      if (searchText === MyLocationTerm) {
        window.navigator.geolocation.getCurrentPosition(
          (position: any) => {
            doSearch({
              location: {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
              },
            });
          },
          () => {
            alert(
              'We have been unable to determine your location. Please try again later.'
            );
          }
        );
      } else {
        doSearch({
          search: searchText,
        });
      }
    }
  };

  const getScreeningTypes = (cinema: any) => {
    const types: any = [];

    if (typeof cinema === 'undefined' || cinema === null) {
      return types;
    }

    const checkScreens = (attribute: any) => {
      if (
        typeof cinema.screens === 'undefined' ||
        cinema.screens === null ||
        cinema.screens.length === 0
      ) {
        return;
      }

      for (let s = 0; s < cinema.screens.length; s++) {
        if (
          typeof cinema.screens[s].accessibility !== 'undefined' &&
          cinema.screens[s].accessibility !== null &&
          cinema.screens[s].accessibility.indexOf(attribute.alias) > -1
        ) {
          types.push(attribute);
          break;
        }
      }
    };

    const checkTags = (attribute: any, tag: string) => {
      if (
        typeof cinema.tags === 'undefined' ||
        cinema.tags === null ||
        typeof cinema.tags.list === 'undefined' ||
        cinema.tags.list === null ||
        cinema.tags.list.length === 0
      ) {
        return;
      }

      for (let t = 0; t < cinema.tags.list.length; t++) {
        if (cinema.tags.list[t] === tag) {
          types.push(attribute);
          break;
        }
      }
    };

    for (let a = 0; a < attributes.length; a++) {
      switch (attributes[a].alias) {
        case 'AUDIO_DESCRIPTION':
          checkScreens(attributes[a]);
          break;
        case 'AUTISM_FRIENDLY':
          checkTags(attributes[a], 'Theater.Service.Autism');
          break;
        case 'DEMENTIA_FRIENDLY':
          checkTags(attributes[a], 'Theater.Service.DementiaFriendly');
          break;
        case 'SUBTITLED':
          checkScreens(attributes[a]);
          break;
      }
    }

    return types;
  };

  const doSearch = (request: any) => {
    setResults([]);

    const tempCinemas: any = [];

    let searchTerm = '';
    let order = '';
    let distance = '';

    if (request && request.search) {
      searchTerm = `search: "${request.search}"`;
      order = '[ALPHABETICAL]';
    }

    if (request && request.location) {
      searchTerm = `
                location: {
                    lat:${request.location.lat},
                    lon:${request.location.lon}
                },
                radius: ${cmsContent.locationRadius}
            `;
      order = '[CLOSEST, ALPHABETICAL]';
      distance = `
                distance (
                    from: {
                        lat:${request.location.lat},
                        lon:${request.location.lon}
                    },
                    unit: "mi"
                )
            `;
    }

    if (searchTerm === '') {
      return;
    }

    let limit = apiLimit;

    if (request && request.limit) {
      limit = request.limit;
    }

    setIsLoading(true);

    const makeCall = (endCursor = null) => {
      let after = '';

      if (endCursor !== null) {
        after = `after: "${endCursor}",`;
      }

      const query = `
        query {
          theaterList (
            affiliation: {
              activity: THEATER_TRADE_GROUP,
              companyId: "${companyId}"
            },
            ${after}
            countries: [UNITED_KINGDOM],
            first: ${limit},
            order: ${order},
            ${searchTerm}
          ) {
            edges {
              node {
                description (locale: en_GB)
                email
                location {
                  address
                  city
                  country
                  region
                  zip
                }
                name
                phone
                screens {
                  accessibility
                  name
                }
                tags {
                  list
                }
                url
                coordinates {
                  latitude
                  longitude
                  ${distance}
                }
              }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
          }
        }
      `;

      graphQuery(query, 'theaterList')
        .then((r) => {
          setNoResults(r.data.theaterList.edges.length > 0 ? false : true);

          if (r.data.theaterList.edges.length === 0) {
            setIsLoading(false);
            scrollToResults();
            return;
          }

          Array.prototype.push.apply(tempCinemas, r.data.theaterList.edges);

          if (r.data.theaterList.pageInfo.hasNextPage) {
            makeCall(r.data.theaterList.pageInfo.endCursor);
          } else {
            completeCall();
          }
        })
        .catch((error) => {
          // console.log(error);
          setNoResults(true);
          completeCall();
        });
    };

    const completeCall = () => {
      const tempData: any = [];

      tempCinemas.forEach((cinema: any, index: number) => {
        if (
          typeof cinema === 'undefined' ||
          cinema === null ||
          typeof cinema.node === 'undefined' ||
          cinema.node === null
        ) {
          return;
        }

        const screeningTypes = getScreeningTypes(cinema.node);

        const screens = cinema.node.screens.filter((screen: any) => {
          return screen.accessibility.indexOf('ACCESSIBLE') > -1;
        });

        const friendlyName = GetFriendlyName(cinema.node.name);

        const title = (
          <span className="cinema-finder__results__item__title">
            <span className="cinema-finder__results__item__title__text">
              {cinema.node.name}
            </span>
            {cinema.node.coordinates.distance && (
              <span className="cinema-finder__results__item__title__subtext">
                {cinema.node.coordinates.distance} miles
              </span>
            )}
          </span>
        );

        const typeContent = (type: any) => {
          return (
            <React.Fragment>
              {type.image && type.image.description && (
                <img
                  alt={type.image.description}
                  className="cinema-finder__results__item__attributes__item__image"
                  loading="lazy"
                  src={`${type.image.file.url}?h=50`}
                  height={type.image.file.details.image.height}
                  width={type.image.file.details.image.width}
                />
              )}
              <span className="cinema-finder__results__item__attributes__item__text">
                {type.name}
              </span>
            </React.Fragment>
          );
        };

        const content = (
          <div className="cinema-finder__results__item__content">
            {cinema.node.description && (
              <div className="cinema-finder__results__item__content__section">
                {cmsContent.accessibilityInformationHeading && (
                  <h4 className="cinema-finder__results__item__content__heading">
                    {cmsContent.accessibilityInformationHeading}
                  </h4>
                )}
                {cinema.node.description && (
                  <div className="cinema-finder__results__item__content__text">
                    {cinema.node.description}
                  </div>
                )}
              </div>
            )}
            {screens.length > 0 && (
              <div className="cinema-finder__results__item__content__section">
                {cmsContent.screensHeading && (
                  <h4 className="cinema-finder__results__item__content__heading">
                    {cmsContent.screensHeading}
                  </h4>
                )}
                <ul className="cinema-finder__results__item__screens">
                  {screens.map((screen: any, screenIndex: number) => (
                    <li
                      key={screenIndex}
                      className="cinema-finder__results__item__screens__item"
                    >
                      {`Screen ${screen.name}`}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {screeningTypes.length > 0 && (
              <div className="cinema-finder__results__item__content__section">
                {cmsContent.screeningTypesHeading && (
                  <h4 className="cinema-finder__results__item__content__heading">
                    {cmsContent.screeningTypesHeading}
                  </h4>
                )}
                <ul className="cinema-finder__results__item__attributes">
                  {screeningTypes.map((type: any, typeIndex: number) => (
                    <li
                      className="cinema-finder__results__item__attributes__item"
                      key={typeIndex}
                    >
                      {type.link && type.link.pageUrl ? (
                        <a
                          aria-label={`find out more about ${type.name} (opens in a new tab)`}
                          href={`/${type.link.pageUrl}/#attribute-${type.alias}`}
                          target="_blank"
                        >
                          {typeContent(type)}
                        </a>
                      ) : (
                        typeContent(type)
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {(cinema.node.url ||
              cinema.node.email ||
              cinema.node.phone ||
              (cinema.node.location &&
                (cinema.node.location.address ||
                  cinema.node.location.city ||
                  cinema.node.location.region ||
                  cinema.node.location.zip))) && (
              <div className="cinema-finder__results__item__content__section">
                {cmsContent.contactHeading && (
                  <h4 className="cinema-finder__results__item__content__heading">
                    {cmsContent.contactHeading}
                  </h4>
                )}
                {cinema.node.url && (
                  <p>
                    <a
                      aria-label={`vist website for ${cinema.node.name} (opens in a new tab)`}
                      href={cinema.node.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Cinema Website
                    </a>
                  </p>
                )}
                {cinema.node.email && (
                  <p>
                    <a href={`mailto:${cinema.node.email}`}>
                      {cinema.node.email}
                    </a>
                  </p>
                )}
                {cinema.node.phone && <p>{cinema.node.phone}</p>}
                {cinema.node.location && (
                  <p>
                    {cinema.node.name}
                    <br />
                    {cinema.node.location.address && (
                      <React.Fragment>
                        {cinema.node.location.address}
                        <br />
                      </React.Fragment>
                    )}
                    {cinema.node.location.city && (
                      <React.Fragment>
                        {cinema.node.location.city}
                        <br />
                      </React.Fragment>
                    )}
                    {cinema.node.location.region && (
                      <React.Fragment>
                        {cinema.node.location.region}
                        <br />
                      </React.Fragment>
                    )}
                    {cinema.node.location.zip && (
                      <React.Fragment>
                        {cinema.node.location.zip}
                      </React.Fragment>
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        );

        tempData.push({
          content,
          id: `cinema-${friendlyName}`,
          isOpen: index === 0 ? true : null,
          title,
        });
      });

      setIsLoading(false);
      setResults(tempData);
      scrollToResults();
    };

    makeCall();
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    setCinemaSelectValue('');
    setCinemaValue('');
    searchCinemas(searchValue);
  };

  const handleSearchChange = (evt: any) => {
    if (
      typeof evt !== 'undefined' &&
      evt !== null &&
      typeof evt.target !== 'undefined' &&
      evt.target !== null &&
      typeof evt.target.value !== 'undefined' &&
      evt.target.value !== null
    ) {
      setSearchValue(evt.target.value);
    }
  };

  const handleCinemaChange = (evt: any) => {
    setCinemaSelectValue(evt.target.value);

    if (
      typeof evt !== 'undefined' &&
      evt !== null &&
      typeof evt.target !== 'undefined' &&
      evt.target !== null &&
      typeof evt.target.value !== 'undefined' &&
      evt.target.value !== null &&
      evt.target.value !== ''
    ) {
      const tempCinemaValue =
        evt.target.options[evt.target.selectedIndex].innerText;

      if (evt.target.value !== window.location.hash) {
        if (
          typeof window.history.pushState === 'undefined' ||
          window.history.pushState === null
        ) {
          window.location.href = `${Sanitise(evt.target.value)}`;
          return;
        }

        window.history.pushState(
          {
            cinemaOption: Sanitise(evt.target.value),
            cinemaValue: tempCinemaValue,
          },
          '',
          evt.target.value
        );

        setCinemaValue(tempCinemaValue);
        setSearchValue('');

        return;
      }

      setCinemaValue(tempCinemaValue);
    }
  };

  const handlePosition = () => {
    setCinemaValue('');
    setCinemaSelectValue('');
    searchCinemas(MyLocationTerm);
  };

  React.useEffect(() => {
    if (isPopStateSetup === false) {
      setIsPopStateSetup(true);

      window.onpopstate = (evt: any) => {
        if (
          typeof evt !== 'undefined' &&
          evt !== null &&
          typeof evt.state !== 'undefined' &&
          evt.state !== null
        ) {
          if (
            typeof evt.state.cinemaValue !== 'undefined' &&
            evt.state.cinemaValue !== null
          ) {
            setCinemaValue(evt.state.cinemaValue);
          }

          if (
            typeof evt.state.cinemaOption !== 'undefined' &&
            evt.state.cinemaOption !== null
          ) {
            setCinemaSelectValue(evt.state.cinemaOption);
          }

          if (
            typeof evt.state.searchValue !== 'undefined' &&
            evt.state.searchValue !== null
          ) {
            searchCinemas(evt.state.searchValue);
          }
        }
      };
    }
  });

  React.useEffect(() => {
    getSelectCinemas();
  }, [rawCinemas]);

  React.useEffect(() => {
    checkPage();
  }, [cinemas]);

  React.useEffect(() => {
    if (calledCheckPageUrl && cinemaValue !== '') {
      searchCinemas();
    }

    if (cinemaValue !== '') {
      document.title = `${cinemaInfo.seoTitle} | ${cinemaValue}`;
      TrackGAPageView();
    } else if (calledCheckPageUrl) {
      document.title = cinemaInfo.seoTitle;
      TrackGAPageView();
    }
  }, [cinemaValue]);

  return (
    <div className="contain">
      <div className="contain__column cinema-finder">
        {props.heading && (
          <h2 className="cinema-finder__title heading-highlighted">
            {props.heading}
          </h2>
        )}

        {props.text && (
          <div className="cinema-finder__text">
            {documentToReactComponents(props.text, MarkupOptions)}
          </div>
        )}

        <div className="cinema-finder__search">
          <form className="cinema-finder__search__form" onSubmit={handleSubmit}>
            <div className="cinema-finder__search__form__field">
              <label
                className="cinema-finder__search__form__field__label"
                htmlFor="cf-cinema"
              >
                {cmsContent.cinemaLabel}
              </label>
              <select
                className="cinema-finder__search__form__field__input"
                disabled={cinemas.length === 0 ? true : false}
                id="cf-cinema"
                onChange={handleCinemaChange}
                value={cinemaSelectValue}
              >
                {cinemas.length === 0 && cmsContent.cinemaLoadingText && (
                  <option value="">{cmsContent.cinemaLoadingText}</option>
                )}
                {cinemas.length > 0 && cmsContent.cinemaPlaceholder && (
                  <option value="">{cmsContent.cinemaPlaceholder}</option>
                )}
                {cinemas &&
                  cinemas.map((cinema: any, index: number) => {
                    if (
                      typeof cinema === 'undefined' ||
                      cinema === null ||
                      typeof cinema.node === 'undefined' ||
                      cinema.node === null ||
                      typeof cinema.node.friendlyName === 'undefined' ||
                      cinema.node.friendlyName === null
                    ) {
                      return null;
                    }

                    return (
                      <option key={index} value={cinema.node.friendlyName}>
                        {cinema.node.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="cinema-finder__search__form__field">
              <MyLocation handlePosition={handlePosition}>
                <label
                  className="cinema-finder__search__form__field__label"
                  htmlFor="cf-location"
                >
                  {cmsContent.locationLabel}
                </label>
              </MyLocation>
              <input
                className="cinema-finder__search__form__field__input"
                id="cf-location"
                list="cf-location-list"
                onChange={handleSearchChange}
                placeholder={cmsContent.locationPlaceholder}
                value={searchValue}
              />
              <datalist id="cf-location-list">
                {cities.length > 0 &&
                  cities.map((city: string, index: number) => (
                    <option key={index} value={city} />
                  ))}
              </datalist>
            </div>
            <div className="cinema-finder__search__form__field">
              <button
                className="cinema-finder__search__form__field__button btn"
                disabled={
                  searchValue === '' || isLoading === true ? true : false
                }
              >
                {cmsContent.buttonText}
              </button>
            </div>
          </form>
        </div>

        {(results.length > 0 || noResults) && cmsContent.resultsHeading && (
          <h2 className="heading-highlighted">{cmsContent.resultsHeading}</h2>
        )}

        <div
          className="cinema-finder__results js-accordion"
          aria-live="polite"
          ref={resultContain}
        >
          {isLoading && cmsContent.resultsLoadingText && (
            <p role="alert">{cmsContent.resultsLoadingText}</p>
          )}
          {results && createContentGroupItem(results, MarkupOptions)}
          {isLoading === false && noResults && cmsContent.noResultsMessage && (
            <div role="alert">
              {documentToReactComponents(
                cmsContent.noResultsMessage.json,
                MarkupOptions
              )}
            </div>
          )}
          <noscript>
            <div role="alert">
              <p>Please enable JavaScript in order to use the cinema finder.</p>
            </div>
          </noscript>
        </div>
      </div>
    </div>
  );
};

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as React from 'react';
import jQuery from 'jquery';

import './content-group.scss';

export const createContentGroup = (node: any, MarkupOptions: any) => {
  if (
    typeof node.data.target.fields !== 'undefined' &&
    node.data.target.fields !== null &&
    typeof node.data.target.fields.contentItems !== 'undefined' &&
    node.data.target.fields.contentItems !== null &&
    typeof node.data.target.fields.contentItems['en-GB'] !== 'undefined' &&
    node.data.target.fields.contentItems['en-GB'] !== null &&
    node.data.target.fields.contentItems['en-GB'].length > 0
  ) {
    return (
      <div className="content-group">
        {createContentGroupItem(
          node.data.target.fields.contentItems['en-GB'],
          MarkupOptions
        )}
      </div>
    );
  }
  return null;
};

export const createContentGroupItem = (items: any, MarkupOptions: any) => {
  return (
    <React.Fragment>
      {items &&
        items.map((item: any, index: number) => {
          return (
            <AccordionItem
              accordionItem={item}
              key={index}
              MarkupOptions={MarkupOptions}
            />
          );
        })}
    </React.Fragment>
  );
};

const AccordionItem = ({ accordionItem, MarkupOptions }: any) => {
  let itemId;
  let itemTitle;
  let itemContent;

  if (typeof accordionItem === 'undefined' || accordionItem === null) {
    return null;
  }

  if (accordionItem.id) {
    itemId = accordionItem.id;
  }

  if (accordionItem.title) {
    itemTitle = accordionItem.title;
  }

  if (accordionItem.content) {
    itemContent = accordionItem.content;
  }

  if (accordionItem.fields) {
    if (accordionItem.fields.name && accordionItem.fields.name['en-GB']) {
      if (typeof itemId === 'undefined') {
        itemId = accordionItem.fields.name['en-GB']
          .toLowerCase()
          .replace(/\s+/gi, '-')
          .replace(/[^a-z0-9_-]+/gi, '')
          .replace(/^-|-$/g, '');
      }
    }

    if (accordionItem.fields.title && accordionItem.fields.title['en-GB']) {
      itemTitle = accordionItem.fields.title['en-GB'];
    }

    if (accordionItem.fields.content && accordionItem.fields.content['en-GB']) {
      itemContent = documentToReactComponents(
        accordionItem.fields.content['en-GB'],
        MarkupOptions
      );
    }
  }

  const itemRef = React.useRef(null);
  const [hasSetup, setHasSetup] = React.useState(false);

  const setupItem = () => {
    if (hasSetup) {
      return;
    }

    setHasSetup(true);

    const item: any = itemRef && itemRef.current ? itemRef.current : null;

    if (
      item === null ||
      jQuery(item).closest('.js-accordion').length === 0 ||
      item.querySelector('.js-accordion-button') !== null
    ) {
      return;
    }

    const title = item.querySelector('.content-group__item__title');
    const text = item.querySelector('.content-group__item__text');

    if (title === null || text === null) {
      return;
    }

    if (typeof text.id === 'undefined' || text.id === null || text.id === '') {
      text.id = generateId('accordion-text-');
    }

    title.innerHTML = `
        <button
            aria-controls="${text.id}"
            aria-expanded="false"
            class="accordion__item__button js-accordion-button"
            type="button"
        >
            <span class="accordion__item__button__inner">
                ${title.innerHTML.trim()}
                <svg
                    aria-hidden="true"
                    class="accordion__item__button__icon"
                    focusable="false"
                    viewBox="0 0 10 10"
                >
                    <rect class="accordion__item__button__icon__vertical" height="8" width="2" y="1" x="4"/>
                    <rect class="accordion__item__button__icon__horizontal" height="2" width="8" y="4" x="1"/>
                </svg>
            </span>
        </button>
    `;

    const button = item.querySelector('.js-accordion-button');

    if (button === null) {
      return;
    }

    button.addEventListener(
      'click',
      () => {
        const slideSpeed = 400;

        const contentSlideDown = () => {
          const otherText = document.querySelectorAll(
            '.content-group__item__text.is-toggled'
          );
          if (otherText.length > 0) {
            for (let t = 0; t < otherText.length; t++) {
              jQuery(otherText[t]).slideUp(slideSpeed, () => {
                otherText[t].className = otherText[t].className
                  .replace('is-toggled', '')
                  .trim();
              });
              const otherButton = document.querySelector(
                `[aria-controls="${otherText[t].id}"]`
              );
              if (otherButton !== null) {
                otherButton.className = otherButton.className
                  .replace('is-toggled', '')
                  .trim();
                otherButton.setAttribute('aria-expanded', 'false');
              }
            }
          }
          jQuery(text).slideDown(slideSpeed);
          text.className += ' is-toggled';
          button.className += ' is-toggled';
          button.setAttribute('aria-expanded', 'true');
        };

        if (button.className.indexOf('is-toggled') > -1) {
          jQuery(text).slideUp(slideSpeed, () => {
            text.className = text.className.replace('is-toggled', '').trim();
          });

          button.className = button.className.replace('is-toggled', '').trim();
          button.setAttribute('aria-expanded', 'false');
        } else {
          if (accordionItem.beforeSlide) {
            button.className += ' is-loading';
            accordionItem.beforeSlide(() => {
              button.className = button.className
                .replace('is-loading', '')
                .trim();
              contentSlideDown();
            });
          } else {
            contentSlideDown();
          }
        }
      },
      false
    );

    text.className += ' accordion__item__text';
    text.style.display = 'none';

    if (item.dataset.accordionOpen) {
      button.click();
    }

    if (
      window.location.hash !== '' &&
      window.location.hash.replace('#', '') === item.id
    ) {
      button.click();
    }

    if (document.body.className.indexOf('js-accordion-hash') === -1) {
      document.body.className += ' js-accordion-hash';

      window.addEventListener(
        'hashchange',
        () => {
          const hash = window.location.hash.replace(/[^a-z0-9_-]+/gi, '');

          const el = document.getElementById(hash);

          if (el === null) {
            return;
          }

          const elButton: any = el.querySelector('.js-accordion-button');

          if (elButton === null) {
            return;
          }

          elButton.click();
        },
        false
      );
    }
  };

  const generateId = (prefix?: any) => {
    const num = Math.floor(Math.random() * 9999);

    return prefix ? prefix + num : num;
  };

  React.useEffect(() => {
    setupItem();
  });

  return (
    <div
      className="content-group__item js-accordion-item"
      id={itemId}
      data-accordion-open={accordionItem.isOpen}
      ref={itemRef}
    >
      {itemTitle && <h3 className="content-group__item__title">{itemTitle}</h3>}
      {itemContent && (
        <div className="content-group__item__text">{itemContent}</div>
      )}
    </div>
  );
};

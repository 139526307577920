import { getCinemas } from '../../utils/GetCinemas';
import {
  getFilmsAndShowtimes,
  Movie,
  getFilmsAndShowtimesResult,
  Showtime,
} from '../../utils/GetFilmsAndShowtimes';
import { getNextWeekDate } from '../../utils/dates';
import { getBestPoster, getBestTitle } from '../../utils/GetLocalizedMovies';
import { FORM_FILTERS_TO_API_FILTERS, FORM_FILTERS } from '../../utils/tags';

const apiLimit = 120;

const toDate = getNextWeekDate();

export type FilmType = {
  movie: {
    localePoster: {
      url: string;
    };
    localeTitle: string;
    localeCertificate: string;
  };
  showtimesFormatted: Array<NewShowtimeType>;
};

export type NewShowtimeType = {
  date: string;
  dateFormatted: string;
  times: Array<{
    time: string;
    url: string;
    tags: string[];
  }>;
};

export const searchCinemas = async (
  request: any,
  locationRadius: string,
  companyId: string
) => {
  let searchTerm = '';
  let order = '';
  let distance = '';
  let showtimesFilter = '';

  if (request && request.search) {
    searchTerm = `search: "${request.search}"`;
    order = '[ALPHABETICAL]';
  }

  if (
    request.screeningType &&
    Object.prototype.hasOwnProperty.call(
      FORM_FILTERS_TO_API_FILTERS,
      request.screeningType
    )
  ) {
    const sc = request.screeningType as FORM_FILTERS;
    showtimesFilter = FORM_FILTERS_TO_API_FILTERS[sc];
  }

  if (request && request.location) {
    searchTerm = `
        location: {
            lat: ${request.location.lat},
            lon: ${request.location.lon}
        },
        radius: ${locationRadius}
    `;
    order = '[CLOSEST, ALPHABETICAL]';
    distance = `
        distance (
            from: {
                lat:${request.location.lat},
                lon:${request.location.lon}
            },
            unit: "mi"
        )
    `;
  }

  // retrieve all cinemas matching the search
  const cinemas = await getCinemas({
    after: null,
    apiLimit,
    companyId,
    distance,
    order,
    searchTerm,
    toDate,
  });

  
  return cinemas
    .filter(({ node }) => {
      return node.showtimesDates.length > 0;
    })
    .filter(({ node }) => {
      if (typeof request.search !== 'undefined') {
        return (
          node.location.city.toLowerCase() === request.search.toLowerCase()
        );
      } else {
        return node;
      }
    })
    .map(({ node }) => {
      return node;
    });
};

export const searchCinemaShowtimes = async (
  companyId: string,
  cinemaId: string,
  showtimesFilter: string
) => {
  const filmsObj = await getFilmsAndShowtimes({
    companyId,
    cinemaId,
    after: null,
    apiLimit,
    toDate,
    showtimesFilter,
  });

  return filmsObj
    .map(
      (result: getFilmsAndShowtimesResult): FilmType => {
        const { movie, showtimes } = result;
        return {
          movie: {
            localePoster: {
              url: getBestPoster(movie),
            },
            localeTitle: getBestTitle(movie),
            localeCertificate:
              movie.mainRelease && movie.mainRelease.certificate
                ? movie.mainRelease.certificate
                : 'tbc',
          },
          showtimesFormatted: formatShowtimes(showtimes),
        };
      }
    )
    .sort((a, b) =>
      a.movie.localeTitle.localeCompare(b.movie.localeTitle, 'en')
    );
};

export const formatShowtimes = (showtimes: Array<Showtime>) => {
  const showtimesData: Array<NewShowtimeType> = [];

  let dateCounter = -1;

  showtimes.forEach((showtime: Showtime) => {
    const tempDate = showtime.startsAt.split(' ')[0];
    const tempDateSplit = tempDate.split('-');
    const d = new Date(
      parseInt(tempDateSplit[0], 10),
      parseInt(tempDateSplit[1], 10) - 1,
      parseInt(tempDateSplit[2], 10)
    );
    const tempDateFormatted = d.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      weekday: 'long',
      year: 'numeric',
    });

    const tempTime = showtime.startsAt.split(' ')[1].slice(0, -3);
    const tempUrl =
      showtime.data.ticketing.length > 0
        ? showtime.data.ticketing[0].urls[0]
        : '';

    if (dateCounter === -1 || showtimesData[dateCounter].date !== tempDate) {
      dateCounter += 1;
      showtimesData[dateCounter] = {
        date: tempDate,
        dateFormatted: tempDateFormatted,
        times: [
          {
            time: tempTime,
            url: tempUrl,
            tags: showtime.tags,
          },
        ],
      };
    } else {
      showtimesData[dateCounter].times.push({
        time: tempTime,
        url: tempUrl,
        tags: showtime.tags,
      });
    }
  });

  return showtimesData;
};

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import * as React from 'react';
import NetlifyForm from 'react-ssg-netlify-forms';
import { MarkupOptions } from '../../utilities';
import { NewsletterRegions } from '../newsletter/newsletter';

import './contact.scss';

interface PropsTypes {
  heading?: string;
  text?: any;
}

export const Contact = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query contactQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
        }
      }
      contact: contentfulContactForm {
        buttonText
        customSubjectOption
        emailFieldLabel
        messageFieldLabel
        messageFormError {
          messageFormError
        }
        messageSendError {
          messageSendError
        }
        nameFieldLabel
        regionFieldLabel
        subjectFieldLabel
      }
      confirm: contentfulPage(pageType: { eq: "contact confirmation" }) {
        pageUrl
      }
    }
  `);

  const [invalidFields, setInvalidFields] = React.useState([]);

  const initialFields: any = {
    email: {
      label: data.contact.emailFieldLabel,
      value: '',
    },
    message: {
      label: data.contact.messageFieldLabel,
      value: '',
    },
    name: {
      label: data.contact.nameFieldLabel,
      value: '',
    },
    region: {
      label: data.contact.regionFieldLabel,
      value: '',
    },
    subject: {
      label: data.contact.subjectFieldLabel,
      value: '',
    },
  };

  const [fields, setFields] = React.useState(initialFields);
  const [formValues, setFormValues] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleChange = (evt: any) => {
    const tempFields: any = {
      ...fields,
    };

    tempFields[evt.target.name].value = evt.target.value;

    setInvalidFields([]);
    setFields(tempFields);
    setFormValues({
      ...formValues,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = () => {
    setIsProcessing(true);
    const tempFields: any = [];

    for (const f in fields) {
      if (fields.hasOwnProperty(f) && fields[f].value === '') {
        tempFields.push(fields[f].label);
      }
    }

    setInvalidFields(tempFields);

    if (tempFields.length > 0) {
      setIsProcessing(false);
      return false;
    }

    return true;
  };

  const handlePostSubmit = () => {
    navigate(`/${data.confirm.pageUrl}/`);
  };

  return (
    <div className="contain">
      <div className="contain__column">
        <div className="contact">
          {props.heading && (
            <h2 className="cinema-finder__title heading-highlighted">
              {props.heading}
            </h2>
          )}

          {props.text && (
            <div className="cinema-finder__text">
              {documentToReactComponents(props.text, MarkupOptions)}
            </div>
          )}

          <NetlifyForm
            formName="contact form"
            formValues={formValues}
            preSubmit={handleSubmit}
            postSubmit={handlePostSubmit}
            automaticHoneypot={true}
          >
            <div className="contact__form">
              <div className="contact__form__columns">
                <div className="contact__form__field">
                  <label
                    className="contact__form__field__label"
                    htmlFor="contact-name"
                  >
                    {fields.name.label} <small>(required)</small>
                  </label>
                  <input
                    type="text"
                    className="contact__form__field__input"
                    id="contact-name"
                    name="name"
                    required={true}
                    onChange={handleChange}
                    value={fields.name.value}
                  />
                </div>
                <div className="contact__form__field">
                  <label
                    className="contact__form__field__label"
                    htmlFor="contact-email"
                  >
                    {fields.email.label} <small>(required)</small>
                  </label>
                  <input
                    type="email"
                    className="contact__form__field__input"
                    id="contact-email"
                    name="email"
                    required={true}
                    onChange={handleChange}
                    value={fields.email.value}
                  />
                </div>
                {NewsletterRegions && (
                  <div className="contact__form__field">
                    <label
                      className="contact__form__field__label"
                      htmlFor="contact-region"
                    >
                      {fields.region.label} <small>(required)</small>
                    </label>
                    <select
                      className="contact__form__field__input"
                      id="contact-region"
                      name="region"
                      required={true}
                      onChange={handleChange}
                      value={fields.region.value}
                    >
                      <option value="">Please choose</option>
                      {NewsletterRegions.map((region: any, index: number) => (
                        <option key={index} value={region.name}>
                          {region.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="contact__form__field">
                  <label
                    className="contact__form__field__label"
                    htmlFor="contact-subject"
                  >
                    {fields.subject.label} <small>(required)</small>
                  </label>
                  <select
                    className="contact__form__field__input"
                    id="contact-subject"
                    name="subject"
                    required={true}
                    onChange={handleChange}
                    value={fields.subject.value}
                  >
                    <option value="">Please choose</option>
                    {data.contact.customSubjectOption && (
                      <option value={data.contact.customSubjectOption}>
                        {data.contact.customSubjectOption}
                      </option>
                    )}
                    {data &&
                      data.attributes &&
                      data.attributes.nodes &&
                      data.attributes.nodes.length > 0 &&
                      data.attributes.nodes.map((item: any, index: number) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="contact__form__field">
                <label
                  className="contact__form__field__label"
                  htmlFor="contact-message"
                >
                  {fields.message.label} <small>(required)</small>
                </label>
                <textarea
                  className="contact__form__field__textarea"
                  id="contact-message"
                  name="message"
                  required={true}
                  onChange={handleChange}
                  value={fields.message.value}
                />
              </div>
              <div className="contact__form__field">
                <button
                  className="btn contact__form__field__button"
                  disabled={isProcessing}
                  type="submit"
                >
                  {data.contact.buttonText}
                </button>
              </div>
              {invalidFields.length > 0 && (
                <div className="contact__form__response" role="alert">
                  <p>{data.contact.messageFormError.messageFormError}</p>

                  <ul>
                    {invalidFields.map((label: string, index: number) => {
                      return <li key={index}>{label}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          </NetlifyForm>
        </div>
      </div>
    </div>
  );
};

import Promise from 'promise-polyfill';
import 'whatwg-fetch';

if (!window.Promise) {
  window.Promise = Promise;
}

(function () {
  const htmlEl = document.querySelector('html');

  if (htmlEl !== null) {
    htmlEl.className = htmlEl.className.replace('no-js', '').trim();
  }
})();

(function () {
  if (window.location.hash.indexOf('#') !== 0) {
    return;
  }

  const checkHash = () => {
    const hash = window.location.hash.replace(/[^a-z0-9_-]+/gi, '');
    const el = document.getElementById(hash);

    if (el === null) {
      return;
    }

    el.scrollIntoView({
      behavior: 'smooth',
    });
  };

  window.onload = () => {
    setTimeout(() => {
      checkHash();
    }, 200);
  };

  window.scrollTo(0, 0);
})();

export const onServiceWorkerUpdateReady = () => {
  const message = document.createElement('div');

  message.innerHTML = `
        <div id="reloadMessage" class="reload-message" role="alert">
            <span class="reload-message__text">
                There are updates to this website, please reload to get the latest version.
            </span>
            <span>
                <button class="btn-3 reload-message__btn" id="reloadMessageDismiss" type="button">Dismiss</button>
                <button class="btn reload-message__btn" id="reloadMessageReload" type="button">Reload</button>
            </span>
        </div>
    `;

  document.body.appendChild(message);

  const contain = document.getElementById('reloadMessage');

  if (contain === null) {
    return;
  }

  setTimeout(() => {
    contain.style.opacity = '1';
  }, 1000);

  const dismiss = document.getElementById('reloadMessageDismiss');

  if (dismiss !== null) {
    dismiss.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        contain.style.opacity = '0';
      },
      false
    );
  }

  const reload = document.getElementById('reloadMessageReload');

  if (reload !== null) {
    reload.addEventListener(
      'click',
      (e) => {
        e.preventDefault();
        window.location.reload(true);
      },
      false
    );
  }
};

import ReactGA from 'react-ga4';

/* tslint:disable:only-arrow-functions */

export const LoadGA = (isDynamicUrl?: boolean) => {
  if (
    typeof process.env.GATSBY_GA_KEY === 'undefined' ||
    process.env.GATSBY_GA_KEY === null
  ) {
    return;
  }
  ReactGA.initialize(process.env.GATSBY_GA_KEY);
  if (isDynamicUrl !== true) {
    TrackGAPageView();
  }
};

export const TrackGAPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: document.location.pathname });
};

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { CinemaContext } from '../cinema-finder/cinema-context';
import { GetUrlParameter, MarkupOptions } from '../../utilities';
import { MyLocation, MyLocationTerm } from '../my-location/my-location';

import './film-finder.scss';

interface PropsTypes {
  heading?: string;
  isComponent?: boolean;
  text?: any;
}

export const FilmFinder = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query FilmFinderQuery {
      filmFinder: contentfulFilmFinder {
        heading
        filmPlaceholder
        filmLabel
        locationPlaceholder
        locationLabel
        screeningTypeLabel
        buttonText
      }
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          alias
        }
      }
      cinemaInfo: contentfulPage(pageType: { eq: "cinema information" }) {
        pageUrl
      }
      searchResults: contentfulPage(pageType: { eq: "search results" }) {
        pageUrl
      }
    }
  `);

  const idSuffix = props.isComponent ? 'component' : 'page';

  const rawCinemas = React.useContext(CinemaContext);

  const [isFormDisabled, setIsFormDisabled] = React.useState(true);
  const [cities, setCities] = React.useState([]);
  const [calledGetSelectCinemas, setCalledGetSelectCinemas] = React.useState(
    false
  );

  const initialValue = {
    film: GetUrlParameter('film'),
    location: GetUrlParameter('location'),
    screeningType: GetUrlParameter('screeningType'),
  };

  const initialFields: any = {
    film: {
      id: `ff-film-${idSuffix}`,
      required: false,
      value: initialValue.film !== '' ? initialValue.film : '',
    },
    location: {
      id: `ff-location-${idSuffix}`,
      required: true,
      value: initialValue.location !== '' ? initialValue.location : '',
    },
    screeningType: {
      id: `ff-screeningtype-${idSuffix}`,
      required: false,
      value:
        initialValue.screeningType !== '' ? initialValue.screeningType : 'All',
    },
  };

  const [fields, setFields] = React.useState(initialFields);

  const getSelectCinemas = () => {
    if (calledGetSelectCinemas || rawCinemas.length === 0) {
      return;
    }

    setCalledGetSelectCinemas(true);

    const tempCitiesObj: any = {};
    const tempCities: any = [];

    rawCinemas.forEach((cinema: any, index: number) => {
      if (typeof cinema.node === 'undefined' || cinema.node === null) {
        return true;
      }

      if (
        typeof cinema.node.location !== 'undefined' &&
        cinema.node.location !== null &&
        typeof cinema.node.location.city !== 'undefined' &&
        cinema.node.location.city !== null &&
        typeof tempCitiesObj[cinema.node.location.city] === 'undefined'
      ) {
        tempCitiesObj[cinema.node.location.city] = true;
        tempCities.push(cinema.node.location.city);
      }
    });

    tempCities.sort();

    setCities(tempCities);
  };

  const handlePosition = () => {
    const tempFields: any = {
      ...fields,
    };

    tempFields.location.value = MyLocationTerm;

    setFields(tempFields);
  };

  const handleChange = (evt: any) => {
    const tempFields: any = {
      ...fields,
    };

    for (const prop in tempFields) {
      if (
        tempFields.hasOwnProperty(prop) &&
        tempFields[prop].id === evt.target.id
      ) {
        tempFields[prop].value = evt.target.value;
        break;
      }
    }

    setFields(tempFields);
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();

    const searchValues = [];

    for (const prop in fields) {
      let isValid = false;

      if (fields.hasOwnProperty(prop) && fields[prop].value !== '') {
        isValid = true;
      }

      if (isValid) {
        searchValues.push(`${prop}=${encodeURIComponent(fields[prop].value)}`);
      }
    }

    window.location.href = `/${data.searchResults.pageUrl}/?${searchValues.join(
      '&'
    )}`;
  };

  React.useEffect(() => {
    getSelectCinemas();
  }, [rawCinemas]);

  React.useEffect(() => {
    let countDisabledFields = 0;

    for (const prop in fields) {
      if (fields.hasOwnProperty(prop)) {
        if (fields[prop].required === true && fields[prop].value === '') {
          countDisabledFields += 1;
        }
      }
    }

    setIsFormDisabled(countDisabledFields !== 0);
  }, [fields]);

  return (
    <div
      className={
        'film-finder' +
        (props.isComponent ? ' film-finder--component' : ' film-finder--page')
      }
    >
      {props.isComponent && props.heading && (
        <h2 className="film-finder__title heading-highlighted">
          {props.heading}
        </h2>
      )}

      {props.isComponent && props.text && (
        <div className="film-finder__text">
          {documentToReactComponents(props.text, MarkupOptions)}
        </div>
      )}

      <form className="film-finder__form" onSubmit={handleSubmit}>
        {props.isComponent !== true &&
          data.filmFinder &&
          data.filmFinder.heading && (
            <h2 className="film-finder__form__heading">
              {data.filmFinder.heading}
            </h2>
          )}
        <div className="film-finder__form__field">
          <MyLocation handlePosition={handlePosition}>
            <label
              className="film-finder__form__field__label"
              htmlFor={fields.location.id}
            >
              {data.filmFinder && data.filmFinder.locationLabel}{' '}
              <small>(required)</small>
            </label>
          </MyLocation>
          <input
            className="film-finder__form__field__input"
            id={fields.location.id}
            list={`ff-location-list-${idSuffix}`}
            placeholder={data.filmFinder && data.filmFinder.locationPlaceholder}
            type="text"
            value={fields.location.value}
            onChange={handleChange}
            required={fields.location.required}
          />
          <datalist id={`ff-location-list-${idSuffix}`}>
            {cities.length > 0 &&
              cities.map((city: string, index: number) => (
                <option key={index} value={city} />
              ))}
          </datalist>
        </div>

        <div className="film-finder__form__field">
          <label
            className="film-finder__form__field__label"
            htmlFor={fields.film.id}
          >
            {data.filmFinder && data.filmFinder.filmLabel}
          </label>
          <input
            className="film-finder__form__field__input"
            id={fields.film.id}
            placeholder={data.filmFinder && data.filmFinder.filmPlaceholder}
            type="text"
            value={fields.film.value}
            onInput={handleChange}
            required={fields.film.required}
          />
        </div>

        <div className="film-finder__form__field">
          <label
            className="film-finder__form__field__label"
            htmlFor={fields.screeningType.id}
          >
            {data.filmFinder && data.filmFinder.screeningTypeLabel}
          </label>

          <select
            className="film-finder__form__field__input"
            id={fields.screeningType.id}
            value={fields.screeningType.value}
            onChange={handleChange}
            required={fields.screeningType.required}
          >
            <option value="All">All Types</option>
            {data &&
              data.attributes &&
              data.attributes.nodes &&
              data.attributes.nodes.length > 0 &&
              data.attributes.nodes.map((item: any, index: number) => (
                <option key={index} value={item.alias}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="film-finder__form__field">
          <button
            className="btn film-finder__form__field__button"
            disabled={isFormDisabled}
          >
            {data.filmFinder && data.filmFinder.buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

import { dotProGraphQuery } from './DotProGraphExternal';
import { Movie, Showtime } from './GetFilmsAndShowtimes';

type getCinemaShowtimesByFilmParameters = {
  filmId: string;
  companyId: string;
  order: string;
  distance: string;
  toDate: string | null;
  cinemaIds: string[];
  showtimesFilter: string;
};

type CinemaShowtimesByFilmType = {
  movie: Movie;
  theater: {
    id: string;
    name: string;
    coordinates: {
      latitude: string;
      longitude: string;
      distance: string;
    };
  };
  showtimes: Showtime[];
};

export const getCinemaShowtimesByFilm = (
  parameters: getCinemaShowtimesByFilmParameters
): Promise<Array<CinemaShowtimesByFilmType>> => {
  const query = `
    query {
      external {
        showtimes {
          byMovie(
            movie: ${atob(parameters.filmId).split(':')[1]},
            to: "${parameters.toDate}",
            theaters: [${parameters.cinemaIds
              .map((id) => `"${atob(id).split(':')[1]}"`)
              .join(',')}],
            ${parameters.showtimesFilter}
          ) {
            movie {
              id
              originalTitle: title
              originalPoster: poster
              en_GB: localeData(locale:"en_GB") {
                title
                synopsis
                poster
              }
              fallback: localeData(locale:"en_US") {
                title
                synopsis
                poster
              }
              mainRelease(country: "uk") {
                  certificate
              }
            }
            theater {
              id
              name
              coordinates {
                latitude
                longitude
                ${parameters.distance}
              }
            }
            showtimes {
              startsAt
              tags
              data {
                ticketing {
                  urls
                }
              }
            }
          }
        }
      }
    }`;

  return new Promise((resolve, reject) => {
    dotProGraphQuery(query, 'external')
      .then(async (r) => {
        if (r === null || r.data.external.showtimes.byMovie.length === 0) {
          resolve([]);
          return;
        }
        resolve(r.data.external.showtimes.byMovie);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

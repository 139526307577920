import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

interface PropsTypes {
  heading?: string;
  text?: any;
}

export const Sitemap = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query SitemapQuery {
      newsListPage: contentfulPage(pageType: { eq: "news list" }) {
        pageUrl
      }
      allContentfulPage(
        filter: { hideOnSitemap: { eq: null } }
        sort: { fields: pageName }
      ) {
        nodes {
          pageName
          pageType
          pageUrl
        }
      }
    }
  `);

  return (
    <div className="contain">
      <div className="contain__column">
        {props.heading && <h2>{props.heading}</h2>}

        {props.text && (
          <div>{documentToReactComponents(props.text, MarkupOptions)}</div>
        )}

        {data &&
          data.allContentfulPage &&
          data.allContentfulPage.nodes &&
          data.allContentfulPage.nodes.length > 0 && (
            <ul>
              {data.allContentfulPage.nodes.map((item: any, index: number) => {
                let pagePath = `/${item.pageUrl}/`;

                switch (item.pageType) {
                  case 'homepage':
                    pagePath = '/';
                    break;
                  case 'news item':
                    pagePath = `/${data.newsListPage.pageUrl}/${item.pageUrl}/`;
                    break;
                }

                return (
                  <li key={index}>
                    <a href={pagePath}>{item.pageName}</a>
                  </li>
                );
              })}
            </ul>
          )}
      </div>
    </div>
  );
};

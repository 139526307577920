import { graphQuery } from './AcGraphApi';

export type Cinema = {
  id: string;
  location: {
    address: string;
    city: string;
    country: string;
    region: string;
    zip: string;
  };
  name: string;
  screens: {
    accessibility: string[];
    name: string;
  };
  tags: {
    list: string[];
  };
  coordinates: {
    latitude: string;
    longitude: string;
    distance: string;
  };
  url: string;
  showtimesDates: string[];
  showtimesData: any;
};

export type getCinemaParameters = {
  companyId: string;
  after: string | null;
  apiLimit: number;
  order: string;
  searchTerm: string | null;
  showtimesFilter?: string | null;
  distance: string;
  toDate: string | null;
};

export const getCinemas = (
  parameters: getCinemaParameters
): Promise<Array<{ node: Cinema }>> => {
  return new Promise((resolve, reject) => {
    let toDate = '';

    if (parameters.toDate) {
      toDate = `to: "${parameters.toDate}"`;
    }

    const query = `
      query {
        theaterList (
          affiliation: {
            activity: THEATER_TRADE_GROUP,
            companyId: "${parameters.companyId}"
          },
          ${parameters.after ?? `after: "${parameters.after}",`}
          countries: [UNITED_KINGDOM],
          first: ${parameters.apiLimit},
          order: ${parameters.order},
          ${parameters.searchTerm ?? parameters.searchTerm}
      ) {
        edges {
          node {
            id
            location {
              address
              city
              country
              region
              zip
            }
            name
            screens {
              accessibility
              name
            }
            tags {
              list
            }
            coordinates {
              latitude
              longitude
              ${parameters.distance}
            }
            url
            showtimesDates (
              country: [UNITED_KINGDOM]
              ${toDate}
            )
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }`;

    graphQuery(query, 'theaterList').then(async (r) => {
      if (r.data.theaterList.edges.length === 0) {
        resolve([]);
      }

      const results = r.data.theaterList.edges;
      if (r.data.theaterList.pageInfo.hasNextPage) {
        const paginatedResults = await getCinemas(
          r.data.theaterList.pageInfo.endCursor
        );
        results.concat(paginatedResults);
      }
      resolve(results);
    });
  });
};

import { dotProGraphQuery } from './DotProGraphExternal';
import { LocaleData } from './GetLocalizedMovies';

export type Movie = {
  id: string;
  originalTitle: string;
  originalPoster: string;
  en_GB: LocaleData;
  fallback: LocaleData;
  mainRelease: {
    certificate: string;
  };
};

export type Showtime = {
  startsAt: string;
  tags: string[];
  data: {
    ticketing: Array<{
      urls: string[];
    }>;
  };
};

export type getFilmsAndShowtimesParameters = {
  cinemaId: string;
  companyId: string;
  after: string | null;
  apiLimit: number;
  toDate: string;
  showtimesFilter: string;
};

export type getFilmsAndShowtimesResult = {
  movie: Movie;
  showtimes: Showtime[];
};

export const getFilmsAndShowtimes = (
  parameters: getFilmsAndShowtimesParameters
): Promise<Array<getFilmsAndShowtimesResult>> => {
  let showtimesFilter = '';
  if (parameters.showtimesFilter) {
    showtimesFilter = `showtimesFilter: ${parameters.showtimesFilter}`;
  }
  return new Promise((resolve, reject) => {
    const query = `
    {
      external {
        showtimes {
          byTheater(
            to: "${parameters.toDate}",
            theater: "${atob(parameters.cinemaId).split(':')[1]}",
            ${showtimesFilter}
          ) {
            movie {
              id
              originalTitle: title
              originalPoster: poster
              en_GB: localeData(locale:"en_GB") {
                title
                synopsis
                poster
              }
              fallback: localeData(locale:"en_US") {
                title
                synopsis
                poster
              }
              mainRelease(country: "uk") {
                  certificate
              }
            }
            showtimes {
              startsAt
              tags
              data {
                ticketing {
                  urls
                  }
              }
            }
          }
        }
      }
    }`;

    dotProGraphQuery(query, 'external')
      .then(async (r) => {
        if (r === null || r.data.external.showtimes.byTheater.length === 0) {
          resolve([]);
          return;
        }
        resolve(r.data.external.showtimes.byTheater);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

const API_ENDPOINT =
  typeof process.env.GATSBY_API_URL !== 'undefined'
    ? process.env.GATSBY_API_URL
    : '';

const API_KEY =
  typeof process.env.GATSBY_API_KEY !== 'undefined'
    ? process.env.GATSBY_API_KEY
    : '';

export function graphQuery(query: string, resultKey: string) {
  return fetch(API_ENDPOINT, {
    body: JSON.stringify({ query }),
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then((r) => r.json())
    .then((r) => {
      if (
        typeof r === 'undefined' ||
        r === null ||
        typeof r.data === 'undefined' ||
        r.data === null ||
        typeof r.data[resultKey] === 'undefined' ||
        r.data[resultKey] === null
      ) {
        return null;
      }
      return r;
    });
}

type FormatOptions = {
  crop?: {
    width: number; // integer, width in px of the cropped image
    height: number; // integer, height in px of the cropped image
  };
  resize?: {
    width: number; // integer, width in px of the cropped image
    height: number; // integer, height in px of the cropped image
  };
  border?: {
    width: number; // integer, width in px of the border
    color: string; // string, color code of the border
  };
};
export const getPictureUrl = (source: string, format: FormatOptions = {}) => {
  const filters = [];
  if (format.crop) {
    const cropParams = ['c'];
    cropParams.push(format.crop.width.toString());
    cropParams.push(format.crop.height.toString());
    filters.push(cropParams.join('_'));
  } else if (format.resize) {
    const resizeParams = ['r'];
    resizeParams.push(format.resize.width.toString());
    resizeParams.push(format.resize.height.toString());
    filters.push(resizeParams.join('_'));
  }
  if (format.border) {
    const borderParams = ['b', format.border.width, format.border.color];
    filters.push(borderParams.join('_'));
  }
  /*
    Overlay filter, format filter & quality filter are intentionally not implemented
   */

  const filtersUrl = filters.length ? `/${filters.join('/')}` : '';
  const path = `${source}${filtersUrl}`;

  return `${document.location.protocol}${path}`;
};

export default getPictureUrl;

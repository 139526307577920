import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

import './attributesSummary.scss';

interface PropsTypes {
  heading?: string;
  text?: any;
}

export const AttributesSummary = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query attributesSummaryQuery {
      attributes: allContentfulScreeningType(
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          alias
          link {
            pageUrl
          }
        }
      }
    }
  `);

  return (
    <div className="attributes-summary">
      <div className="contain">
        <div className="contain__column">
          {props.heading && (
            <h2 className="heading-highlighted">{props.heading}</h2>
          )}

          {props.text && (
            <div>{documentToReactComponents(props.text, MarkupOptions)}</div>
          )}

          {data &&
            data.attributes &&
            data.attributes.nodes &&
            data.attributes.nodes.length > 0 && (
              <div className="attributes-summary__list">
                {data.attributes.nodes.map((item: any, index: number) => (
                  <div key={index} className="attributes-summary__list__item">
                    {item.image && item.image.description && (
                      <div className="attributes-summary__list__item__image">
                        <img
                          alt={item.image.description}
                          loading="lazy"
                          src={`${item.image.file.url}?h=140`}
                          height={item.image.file.details.image.height}
                          width={item.image.file.details.image.width}
                        />
                      </div>
                    )}
                    {item.name && (
                      <h3 className="attributes-summary__list__item__title">
                        {item.name}
                      </h3>
                    )}

                    {item.link && item.link.pageUrl && (
                      <a
                        aria-label={`find out more about ${item.name}`}
                        className="attributes-summary__list__item__link btn-2"
                        href={`/${item.link.pageUrl}/#attribute-${item.alias}`}
                      >
                        Find out more
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

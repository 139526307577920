import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MarkupOptions } from '../../utilities';

import './carousel.scss';

interface PropsTypes {
  heading?: string;
  text?: any;
}

export const Carousel = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query CarouselQuery {
      partners: allContentfulPartner(sort: { fields: name, order: ASC }) {
        nodes {
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
        }
      }
    }
  `);

  const [isSetup, setIsSetup] = React.useState(false);

  const setupCarousel = () => {
    setIsSetup(true);

    if ('IntersectionObserver' in window && 'requestAnimationFrame' in window) {
      const carousel = document.querySelector('.js-carousel');

      if (carousel === null) {
        return;
      }

      const carouselList = carousel.querySelector('.js-carousel-list');

      if (carouselList === null) {
        return;
      }

      const extraItems = carouselList.innerHTML;

      carouselList.innerHTML += extraItems;

      const items = carouselList.querySelectorAll('.js-carousel-item');

      const lastItem = items[items.length / 2 - 1];

      if (lastItem === null) {
        return;
      }

      let hasFocus = false;
      let hasHover = false;
      let isStop = false;
      let startTimeout: any;

      const move = () => {
        if (isStop) {
          isStop = false;
          return;
        }
        if (hasFocus === false && hasHover === false) {
          carousel.scrollLeft = carousel.scrollLeft + 1;
          window.requestAnimationFrame(move);
        }
      };

      const startMove = () => {
        isStop = false;
        clearTimeout(startTimeout);
        startTimeout = setTimeout(() => {
          window.requestAnimationFrame(move);
        }, 500);
      };

      const stopMove = () => {
        isStop = true;
        clearTimeout(startTimeout);
      };

      const observer = new IntersectionObserver(
        (changes, observe) => {
          changes.forEach((change) => {
            if (change.isIntersecting) {
              startMove();
              observe.unobserve(carouselList);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        }
      );

      observer.observe(carouselList);

      carousel.style.overflow = 'hidden';

      const observerItem = new IntersectionObserver(
        (changes, observe) => {
          changes.forEach((change) => {
            if (change.isIntersecting === false) {
              carousel.scrollLeft = 0;
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        }
      );

      observerItem.observe(lastItem);

      carousel.addEventListener(
        'focus',
        (event) => {
          stopMove();
          hasFocus = true;
        },
        false
      );

      carousel.addEventListener(
        'blur',
        (event) => {
          hasFocus = false;
          startMove();
        },
        false
      );

      carousel.addEventListener(
        'mouseover',
        (event) => {
          stopMove();
          hasHover = true;
        },
        false
      );

      carousel.addEventListener(
        'mouseout',
        (event) => {
          hasHover = false;
          startMove();
        },
        false
      );

      carousel.addEventListener('keydown', (event: any) => {
        switch (event.keyCode) {
          case 37:
            carousel.scrollLeft = carousel.scrollLeft - 10;
            break;
          case 39:
            carousel.scrollLeft = carousel.scrollLeft + 10;
            break;
        }
      });
    }
  };

  React.useEffect(() => {
    if (isSetup === false) {
      setupCarousel();
    }
  });

  return (
    <div className="contain">
      <div className="contain__column">
        {props.heading && (
          <h2 className="heading-highlighted">{props.heading}</h2>
        )}

        {props.text && (
          <div>{documentToReactComponents(props.text, MarkupOptions)}</div>
        )}

        {data && data.partners && data.partners.nodes && (
          <div
            aria-label={`${props.heading} carousel`}
            className="carousel js-carousel"
            role="region"
            tabIndex={0}
          >
            <ul className="carousel__list js-carousel-list">
              {data.partners.nodes.map((item: any, index: number) => {
                if (
                  typeof item === 'undefined' ||
                  item === null ||
                  typeof item.image === 'undefined' ||
                  item.image === null ||
                  typeof item.image.description === 'undefined' ||
                  item.image.description === null
                ) {
                  return null;
                }

                return (
                  <li
                    key={index}
                    className="carousel__list__item js-carousel-item"
                  >
                    <img
                      alt={item.image.description}
                      className="carousel__list__item__image js-carousel-item-image"
                      loading="lazy"
                      src={`${item.image.file.url}?h=100`}
                      height={item.image.file.details.image.height}
                      width={item.image.file.details.image.width}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

import { graphQuery } from '../../utils/AcGraphApi';
import { getCinemas } from '../../utils/GetCinemas';
import { getBestPoster, getBestTitle } from '../../utils/GetLocalizedMovies';
import { formatShowtimes, NewShowtimeType } from './searchCinemasAndShowtimes';
import { getNextWeekDate } from '../../utils/dates';
import { getCinemaShowtimesByFilm } from '../../utils/getCinemaShowtimesByFilm';
import { FORM_FILTERS_TO_API_FILTERS, FORM_FILTERS } from '../../utils/tags';

const apiLimit = 120;

const toDate = getNextWeekDate();

export const searchFilms = async (
  request: any,
  locationRadius: string,
  companyId: string
) => {
  let searchTerm = '';
  let order = '';
  let distance = '';
  let distanceDotPro = '';
  let showtimesFilter = '';

  if (request && request.search) {
    searchTerm = `search: "${request.search}"`;
    order = '[ALPHABETICAL]';
  }

  if (
    request.screeningType &&
    Object.prototype.hasOwnProperty.call(
      FORM_FILTERS_TO_API_FILTERS,
      request.screeningType
    )
  ) {
    const sc = request.screeningType as FORM_FILTERS;
    showtimesFilter = `showtimesFilter: ${FORM_FILTERS_TO_API_FILTERS[sc]}`;
  }

  if (request && request.location) {
    searchTerm = `
            location: {
                lat: ${request.location.lat},
                lon: ${request.location.lon}
            },
            radius: ${locationRadius}
        `;
    order = '[CLOSEST, ALPHABETICAL]';
    distance = `
            distance (
                from: {
                    lat:${request.location.lat},
                    lon:${request.location.lon}
                },
                unit: "mi"
            )
        `;
    distanceDotPro = `
            distance (
                from: {
                    latitude:${request.location.lat},
                    longitude:${request.location.lon}
                },
                unit: MI
            )
        `;
  }

  // search for film
  const films = await getFilms({
    after: null,
    film: request.film,
  });

  if (films.length === 0) {
    return [];
  }

  const filmIds: Array<string> = films.map(
    (item: searchType) => item.node.node.id
  );

  // search for cinemas
  const cinemas = await getCinemas({
    after: null,
    apiLimit,
    companyId,
    distance,
    order,
    searchTerm,
    toDate,
  });

  if (cinemas.length === 0) {
    return [];
  }

  const cinemaIds = cinemas
    .filter(({ node }) => {
      return node.showtimesDates.length > 0;
    })
    .map(({ node }) => node.id);

  const filmShowtimes = await Promise.all(
    filmIds.map((filmId) =>
      getCinemaShowtimesByFilm({
        cinemaIds,
        companyId,
        distance: distanceDotPro, // distance parameters from DotPro external are using a different format than AC Graph
        filmId,
        order,
        toDate,
        showtimesFilter,
      }).then((results) => {
        return {
          ...results.map((result) => {
            const { movie, showtimes, theater } = result;
            return {
              movie: {
                localePoster: {
                  url: getBestPoster(movie),
                },
                localeTitle: getBestTitle(movie),
                localeCertificate:
                  movie.mainRelease && movie.mainRelease.certificate
                    ? movie.mainRelease.certificate
                    : 'tbc',
              },
              showtimesFormatted: formatShowtimes(showtimes),
              theater,
            };
          }),
        };
      })
    )
  );

  const cinemaObj: any = {};
  filmShowtimes.forEach((parentItem) => {
    for (const i in parentItem) {
      if (parentItem.hasOwnProperty(i)) {
        const item = parentItem[i];

        if (typeof cinemaObj[item.theater.id] === 'undefined') {
          cinemaObj[item.theater.id] = {
            ...item.theater,
            showtimesData: [item],
          };
        } else {
          cinemaObj[item.theater.id].showtimesData.push(item);
        }
      }
    }
  });
  const cinemaArray = Object.values(cinemaObj);
  cinemaArray.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

  return cinemaArray;
};

type searchParameters = {
  after: string | null;
  film: string;
};

type searchType = {
  node: {
    node: {
      id: string;
      stringValue: string;
    };
  };
};

const getFilms = (parameters: searchParameters): Promise<Array<searchType>> => {
  const query = `
        query {
            search(
                ${parameters.after ? `after: "${parameters.after}",` : ''}
                text: "${parameters.film}",
                types: [Movie],
                first: 100
            ) {
                edges {
                    node {
                        node {
                            id
                            stringValue
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
                totalCount
            }
        }
    `;

  return new Promise((resolve, reject) => {
    graphQuery(query, 'search').then(async (r) => {
      if (r.data.search.edges.length === 0) {
        resolve([]);
      }

      let results = r.data.search.edges;

      if (r.data.search.pageInfo.hasNextPage) {
        const paginatedResults = await getFilms({
          film: parameters.film,
          after: r.data.search.pageInfo.endCursor,
        });

        results = [...results, ...paginatedResults];
      }

      resolve(results);
    });
  });
};

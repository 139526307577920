import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { MyLocationTerm } from '../my-location/my-location';

import './film-results.scss';
import { Cinema } from '../../utils/GetCinemas';
import { searchFilms } from './searchFilms';
import FilmResultsItem from './film-results-item';
import { GetUrlParameter, MarkupOptions } from '../../utilities';
import { searchCinemas } from './searchCinemasAndShowtimes';

interface PropsTypes {
  heading?: string;
  text?: any;
}

const companyId =
  typeof process.env.GATSBY_API_ID !== 'undefined'
    ? process.env.GATSBY_API_ID
    : '';

export const FilmResults = (props: PropsTypes) => {
  const data = useStaticQuery(graphql`
    query FilmResultsQuery {
      cinemaFinder: contentfulCinemaFinder {
        locationRadius
      }
      filmFinder: contentfulFilmFinder {
        resultsLoadingText
        noResultsMessage {
          json
        }
      }
      settings: contentfulWebsiteSettings {
        placeholderImage {
          description
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
    }
  `);

  const cmsContent = data.cinemaFinder;

  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState<Cinema[]>([]);
  const [noResults, setNoResults] = React.useState(false);
  const [calledCheckPageUrl, setCalledCheckPageUrl] = React.useState(false);
  const [accordionClass, setAccordionClass] = React.useState('');

  const checkPageUrl = () => {
    if (calledCheckPageUrl) {
      return;
    }

    setCalledCheckPageUrl(true);

    const location = GetUrlParameter('location');
    const screeningType = GetUrlParameter('screeningType');
    const film = GetUrlParameter('film');

    if (location === '' && screeningType === '' && film === '') {
      setNoResults(true);
      return;
    }

    if (
      typeof window !== 'undefined' &&
      window !== null &&
      typeof window.CSS !== 'undefined' &&
      window.CSS !== null &&
      typeof window.CSS.supports !== 'undefined' &&
      window.CSS.supports !== null &&
      window.CSS.supports('display', 'grid') === true
    ) {
      setAccordionClass('js-accordion');
    }

    if (location === MyLocationTerm) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          doSearch({
            film,
            location: {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            },
            screeningType,
          });
        },
        () => {
          alert(
            'We have been unable to determine your location. Please try again later.'
          );
        }
      );
    } else {
      doSearch({
        film,
        screeningType,
        search: location,
      });
    }
  };

  const doSearch = async (parameters: {
    film?: string;
    screeningType?: string;
    search?: string;
    location?: {
      lat: number;
      lon: number;
    };
  }) => {
    setIsLoading(true);

    let res: any = [];

    if (parameters.film !== '') {
      res = await searchFilms(parameters, cmsContent.locationRadius, companyId);
    } else {
      res = await searchCinemas(
        parameters,
        cmsContent.locationRadius,
        companyId
      );
    }

    setResults(res);
    setNoResults(res.length > 0 ? false : true);
    setIsLoading(false);
  };

  const defaultImage = {
    alt: data.settings.placeholderImage.description,
    class: 'placeholder-image',
    height: data.settings.placeholderImage.file.details.image.height,
    url: `${data.settings.placeholderImage.file.url}`,
    width: data.settings.placeholderImage.file.details.image.width,
  };

  React.useEffect(() => {
    checkPageUrl();
  });

  return (
    <div className="contain">
      <div className="contain__column">
        <div className={`film-results ${accordionClass}`}>
          <div className="film-results__results js-accordion">
            {results &&
              results.map((result, index) => (
                <FilmResultsItem
                  {...result}
                  defaultImage={defaultImage}
                  key={index}
                />
              ))}
          </div>

          {isLoading && data.filmFinder && data.filmFinder.resultsLoadingText && (
            <div className="film-results__message" role="alert">
              <p>{data.filmFinder.resultsLoadingText}</p>
            </div>
          )}

          {noResults &&
            data.filmFinder &&
            data.filmFinder.noResultsMessage &&
            data.filmFinder.noResultsMessage.json && (
              <div className="film-results__message" role="alert">
                {documentToReactComponents(
                  data.filmFinder.noResultsMessage.json,
                  MarkupOptions
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
